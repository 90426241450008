import React   from 'react';
import { Box } from '@chakra-ui/react';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia  from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Center       from '@interness/web-core/src/components/structure/Center/Center';


const IsolierungenPage = (props) => {
  return (
    <>
      <HeaderMedia id="header-isolierung"/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle="Wir dämmen Ihr Haus richtig">Isolierung - Dämmung</Heading>
        <Box textAlign="justify">
          <p>Damit Sie sich in Ihrem Haus nicht nur behaglich fühlen, sondern auch von hohen Energiekosten entlastet
            werden, planen wir mit Ihnen gemeinsam eine kostengünstige Dämmung die Ihren speziellen Anforderungen
            entspricht.
            <br/><br/>
            Vielfältige Einsatzmöglichkeiten und Materialen gewährleisten optimale Dämmung.
            <br/><br/>
            Ob Dach, Außenwand und Fassade klären wir mit Ihnen individuell vor Ort.
            <br/><br/>
            Als äußert effektiv hat sich die Aufdachdämmung erwiesen. Dabei lässt sich die Dachdämmung grundlegend in
            verschiedene Kategorien unterteilen:
            <br/><br/>
            - Flachdachdämmung
            - Aufsparrendämmung
            - Zwischensparrendämmung
            - Untersparrendämmung
            - Dachbodendämmung bzw. Dämmung der obersten Geschossdecke</p>
          <p>Der Wärmeverlust über das Dach, bedingt durch eine schlechte Dämmung, kann bis zu 20% des gesamten
            Wärmeverlustes eines Hauses ausmachen. Im Verhältnis zu schlecht isolierten Fenstern ist der Wert doppelt so
            hoch, im Verhältnis zum Keller ist der Wert des Wärmeverlustes sogar fünfmal so hoch.
            <br/><br/>
            Stellt sich noch die Frage des Materials. Ökologische, mineralische oder fossile Dämmstoffe stehen heute für
            die unterschiedlichen Anforderungen zur Verfügung.
            <br/><br/>
            Sprechen Sie uns an, damit wir Sie umfassend und kompetent beraten können. Tel.: +49 (0) 2353 - 12409
            <br/><br/>
            Eine professionelle Ausführung der Wärmeschutzdämmung ist dabei selbstverständlich.
          </p>
        </Box>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default IsolierungenPage;